<template>
  <div class="quote-step-container last">
    <div class="quote-question">
      <h1>Your AC Club Quote</h1>
      <p class="subheader">Lock in this price for 12 months by joining waiting list below.</p>
    </div>
    <div class="quote-container">

      <div class="row quote-items">

        <div class="quote-item pb-3">
          <div class="quote-item-img">
            <img class="" alt="Goodman Unit" :src="getHvacImgSrc(hvacBrand)">
          </div>
          <div class="quote-item-details">
            {{hvacBrand | capitalize}} {{hvacModel}}
            <br/>
            {{tonnage}}-tons | {{hvacSeer}} SEER
            <!-- <br/> -->
            <!-- <p>Installation also includes a new drain line, 4" Media Cabinet with filters for life, and an attic stairway insulated enclosure.</p> -->
          </div>
          <div class="quote-item-price">
            ${{hvacCost}}/mo
          </div>
          <div class="quote-item-additional">
            <p class="mb-4">Installation includes Outside Condenser Unit, Inside Air Handler, Furnace and Evaporator Coil, new pads, pans, plenums, stands, float switches, a new drain line (not just flushing your existing line), attic stairway insulated enclosure, and 4" media cabinet with filters for life.</p>
          </div>        
        </div>

        <div class="quote-item">
          <div class="quote-item-img">
            <img class="" alt="" :src="getThermostatImgSrc(thermostat)">
          </div>
          <div class="quote-item-details">
            {{thermostat | capitalize}} {{thermostatModel}}
          </div>
          <div class="quote-item-price">
            {{thermostatCost | pricing}}
          </div> 
        </div>

        <div class="quote-item" v-show="wasAirSanitizerSelected">
          <div class="quote-item-img">
            <img class="" alt="" :src="getAirSanitizerImgSrc(airSanitizer)">
          </div>
          <div class="quote-item-details">
            <span v-show="wasAirSanitizerSelected">{{airSanitizer | capitalize}} {{airSanitizerModel}}</span> <span v-show="!wasAirSanitizerSelected">{{noAirSanitizerMsg}}</span>
          </div>
          <div class="quote-item-price">
            ${{airSanitizerCost}}/mo
          </div> 
        </div>

      </div>

      <!-- <div class="row includes">
        <h4>Installation also includes:</h4>
        <div class="line-item--single has-bottom-border">
          <div>New drain line</div>
          <div>Free | $650 value</div>
        </div>
        <div class="line-item--single has-bottom-border">
          <div>4" Media Cabinet</div>
          <div>Free | $400 value</div>
        </div>
        <div class="line-item--single">
          <div>Attic Stairway Insulated Enclosure</div>
          <div>Free | $250 value</div>
        </div>
      </div> -->

      <div class="row additional-work">
        <p>We provide upfront and monthly payment options for Ductwork, Extensive Electrical, Extensive Permitting, Relocating units, and Insulation.</p>
        <!-- <h4>Additional work:</h4>
        <div class="line-item--single has-bottom-border">
          <div>Ductwork</div>
          <div>Custom Quote</div>
        </div>
        <div class="line-item--single has-bottom-border">
          <div>Insulation</div>
          <div>Custom Quote</div>
        </div>
        <div class="line-item--single has-bottom-border">
          <div>Extensive Electrical</div>
          <div>Custom Quote</div>
        </div>
        <div class="line-item--single">
          <div>Permitting</div>
          <div>Custom Quote</div>
        </div> -->
      </div>

      <div class="row total">
        <div class="line-item--single">
          <p class="line-item--title">Your AC Club Membership Quote</p>
          <p class="line-item--value">${{quoteTotal}}/mo</p>
        </div>
      </div>

      <div class="row email">
        <!-- Enter Email -->
        <div v-show="!wasEmailSent">
          <h3>Join waiting list to lock in price quote for 12 months</h3>
          <div class="email-input">
            <input type="text" name="email" v-model="email" placeholder="name@email.com">
            <button @click="sendEmail()" class="btn btn--email">Send</button>
          </div>
          <div class="error" v-show="isMissingEmail">Email is required.</div>
        </div>
        <!-- Email Success -->
        <div v-show="wasEmailSent">
          <h3>Thank you!</h3>
          <p>This quote was sent to <span style="font-weight:bold;">{{this.email}}</span> and was locked in for one year. When you're ready, schedule a consultation to receive the next steps.</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

import quoteBuilderMixin from '@/mixins/quoteBuilderMixin';

export default {
  name: 'QuoteResults',
  mixins: [quoteBuilderMixin],
  data() {
    return {
      tonnage: 0,
      hvacBrand: "",
      hvacModel: "",
      hvacSeer: "",
      hvacSpeed: "",
      hvacCost: 0,
      airSanitizer: "",
      airSanitizerModel: "",
      airSanitizerCost: 0,
      thermostat: "",
      thermostatModel: "",
      thermostatCost: 0,
      quoteTotal: 0,
      email: "",
      wasEmailSent: false,
      wasAirSanitizerSelected: false,
      noAirSanitizerMsg: "No Air Sanitizer",
      zapUrl: "",
      // Assets hosted on Hubspot for email
      hvacBrandEmailImgUrl: "",
      airSanitizerEmailImgUrl: "",
      thermostatEmailImgUrl: "",
      // Email Errors
      isMissingEmail: false,
    }
  },
  created() {

    // Set quote controls visibility
    this.$store.state.quoteControls.isVisible = false;
    
    // Collect all the stored information and show in the UI
    const tonnage = this.$store.state.tonnage;
    const hvacBrand = this.$store.state.hvacBrand;
    const airSanitizer = this.$store.state.airSanitizer;
    const thermostat = this.$store.state.thermostat;
    const startingUrl = this.$store.state.startingUrl;
    this.tonnage = tonnage;

    // Set nav link css active class
    this.$store.state.navigation.isLifestylePageActive = false;
    this.$store.state.navigation.isAirQualityPageActive = false;
    this.$store.state.navigation.isThermostatPageActive = false;
    this.$store.state.navigation.isQuotePageActive = true;

    // Page Refresh Route Gaurd - Goes to home
    // When it's reloaded, all the data is wiped out, so there is no 
    // ...longer a hvac brand for referrences
    if (hvacBrand === "") {
      this.$router.push('/');
    }

    // Set app and email URL images
    if (hvacBrand == "goodman") {
      this.hvacBrandImgSrc = "goodman-unit.png";
      this.hvacBrandEmailImgUrl = "https://f.hubspotusercontent00.net/hubfs/8339855/AC%20Club/quote-builder/goodman-unit.png";
    }
    else if (hvacBrand == "daikin") {
      this.hvacBrandEmailImgUrl = "https://f.hubspotusercontent00.net/hubfs/8339855/AC%20Club/quote-builder/daikin-unit.png";
    }
    else if (hvacBrand == "trane") {
      this.hvacBrandEmailImgUrl = "https://f.hubspotusercontent00.net/hubfs/8339855/AC%20Club/quote-builder/trane-unit.png";
    }

    if (airSanitizer == "plasmapure") {
      this.airSanitizerEmailImgUrl = "https://f.hubspotusercontent00.net/hubfs/8339855/AC%20Club/quote-builder/plasmapure-air-sanitizer.png";
    }
    else if (airSanitizer == "halo-led") {
      this.airSanitizerEmailImgUrl = "https://f.hubspotusercontent00.net/hubfs/8339855/AC%20Club/quote-builder/halo-led-air-sanitizer.png";
    }

    if (thermostat == "honeywell") {
      this.thermostatEmailImgUrl = "https://f.hubspotusercontent00.net/hubfs/8339855/AC%20Club/quote-builder/honeywell-thermostat.png";
    }
    else if (thermostat == "nest") {
      this.thermostatEmailImgUrl = "https://f.hubspotusercontent00.net/hubfs/8339855/AC%20Club/quote-builder/nest-thermostat.png";
    }
    else if (thermostat == "wyze") {
      this.thermostatEmailImgUrl = "https://f.hubspotusercontent00.net/hubfs/8339855/AC%20Club/quote-builder/wyze-thermostat.png";
    }
    else if (thermostat == "daikin") {
      this.thermostatEmailImgUrl = "https://f.hubspotusercontent00.net/hubfs/8339855/AC%20Club/quote-builder/daikin-thermostat.png";
    }
    else if (thermostat == 'trane') {
      this.thermostatEmailImgUrl = "https://f.hubspotusercontent00.net/hubfs/8339855/AC%20Club/quote-builder/trane-thermostat.png";
    }

    // Set Hvac Details
    this.hvacBrand = hvacBrand;

    if (hvacBrand == "goodman") {
      this.hvacModel = "GSX16";
      this.hvacSeer = 16;
      this.hvacSpeed = "Single Speed";
    }
    else if (hvacBrand == "daikin") {
      this.hvacModel = "Fit";
      this.hvacSeer = 18;
      this.hvacSpeed = "Inverter Variable Speed";
    }
    else if (hvacBrand == "trane") {
      this.hvacModel = "XV20i";
      this.hvacSeer = 20;
      this.hvacSpeed = "Variable Speed";
    }

    // 2-tons
    if (hvacBrand == "goodman" && tonnage == 2) {
      this.hvacCost = 78;
    }
    else if (hvacBrand == "daikin" && tonnage == 2) {
      this.hvacCost = 104;
    }
    else if (hvacBrand == "trane" && tonnage == 2) {
      this.hvacCost = 120;
    }

    // 2.5-tons
    if (hvacBrand == "goodman" && tonnage == 2.5) {
      this.hvacCost = 78;
    }

    // 3-tons
    if (hvacBrand == "goodman" && tonnage == 3) {
      this.hvacCost = 79;
    }
    else if (hvacBrand == "daikin" && tonnage == 3) {
      this.hvacCost = 109;
    }
    else if (hvacBrand == "trane" && tonnage == 3) {
      this.hvacCost = 125;
    }

    // 3.5-tons
    if (hvacBrand == "goodman" && tonnage == 3.5) {this.hvacCost = 85;}

    // 4-tons
    if (hvacBrand == "goodman" && tonnage == 4) {
      this.hvacCost = 89;
    }
    else if (hvacBrand == "daikin" && tonnage == 4) {
      this.hvacCost = 119;
    }
    else if (hvacBrand == "trane" && tonnage == 4) {
      this.hvacCost = 131;
    }

    // 5-tons
    if (hvacBrand == "goodman" && tonnage == 5) {
      this.hvacCost = 99;
    }
    else if (hvacBrand == "daikin" && tonnage == 5) {
      this.hvacCost = 125;
    }
    else if (hvacBrand == "trane" && tonnage == 5) {
      this.hvacCost = 140;
    }

    // Set Air Sanitizer Details
    this.airSanitizer = airSanitizer;

    if (airSanitizer == "plasmapure") {
      this.airSanitizerCost = 7;
      this.airSanitizerModel = "600";
      this.wasAirSanitizerSelected = true;
    }
    else if (airSanitizer == "halo-led") {
      this.airSanitizerCost = 10;
      this.airSanitizerModel = ""
      this.wasAirSanitizerSelected = true;
    } 
    else if (airSanitizer == "none") {
      this.airSanitizerCost = 0;
      this.airSanitizerModel = "";
      this.wasAirSanitizerSelected = false;
    }

    // Set thermostat Details
    this.thermostat = thermostat

    if (thermostat == "honeywell") {
      this.thermostatCost = 0;
      this.thermostatModel = "Lyric T6"
    }
    else if (thermostat == "nest") {
      this.thermostatCost = 0;
      this.thermostatModel = "Thermostat";
    }
    else if (thermostat == "daikin") {
      this.thermostatCost = 0;
      this.thermostatModel = "One+";
    }
    else if (thermostat == "wyze") {
      this.thermostatCost = 0;
      this.thermostatModel = "";
    }

    // Set Quote Total
    this.quoteTotal = (this.hvacCost + this.airSanitizerCost + this.thermostatCost);
  
  },
  methods: {

    getHvacImgSrc(unit) {
      return require('../assets/images/' + unit + '-unit.png');
    },

    getThermostatImgSrc(thermostat) {
      return require('../assets/images/' + thermostat + '-thermostat.png');
    },
    
    getAirSanitizerImgSrc(sanitizer) {
      return require('../assets/images/' + sanitizer + '-air-sanitizer.png');
    },

    // Prepare quote object to send to Zapier Webhook
    sendEmail() {

      // If email is filled out => Send Payload to Zapier
      if (this.email != "") {

          this.isMissingEmail = false;// Shows form error
          this.wasEmailSent = true;// Shows success message

          // These urls will send it to the right webhook on zapier
          // ...based on if they have selected and air sanitizer or not
          if (this.$store.state.airSanitizer == "none") {
            this.zapUrl = "https://hooks.zapier.com/hooks/catch/10697012/b41ayxk/silent";
          } 
          else {
            this.zapUrl = "https://hooks.zapier.com/hooks/catch/10697012/buph5w0/silent/";
          }
          

          const payload = JSON.stringify({
          "email": this.email,
          "tonnage": this.tonnage,
          "hvacBrand": this.hvacBrand,
          "hvacModel": this.hvacModel,
          "hvacSeer": this.hvacSeer,
          "hvacSpeed": this.hvacSpeed,
          "hvacCost": this.hvacCost ? `$${this.hvacCost}/mo` : 'Included',
          "airSanitizer": this.airSanitizer,
          "airSanitizerModel": this.airSanitizerModel,
          "airSanitizerCost": this.airSanitizerCost ? `$${this.airSanitizerCost}/mo` : 'Included',
          "thermostat": this.thermostat,
          "thermostatModel": this.thermostatModel,
          "thermostatCost": this.thermostatCost ? `$${this.thermostatCost}/mo` : 'Included',
          "quoteTotal": `$${this.quoteTotal}/mo`,
          "hvacBrandEmailImgUrl": this.hvacBrandEmailImgUrl,
          "airSanitizerEmailImgUrl": this.airSanitizerEmailImgUrl,
          "thermostatEmailImgUrl": this.thermostatEmailImgUrl,
          "hvacPerformance": localStorage.hvacPerformance || '',
          "hvacAge": localStorage.hvacAge || '',
        });

        axios.post(this.zapUrl, payload);

        localStorage.removeItem('hvacPerformance');
        localStorage.removeItem('hvacAge');
      } else {
        this.isMissingEmail = true;// Hides form error
        this.wasEmailSent = false;// Hides success message
      }
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  }
}
</script>

<style lang="scss">
.email-input {
  display: flex;
  position: relative;
}

.error {
  padding: 8px 16px;
  margin-top: 8px;
  text-align: left;
  background-color: rgba(230, 73, 73, 0.85);
  border-radius: 6px;
}

input {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(.5em - 1px);
  padding-left: calc(.75em - 1px);
  padding-right: calc(.75em - 1px);
  padding-top: calc(.5em - 1px);
  position: relative;
  vertical-align: top;
  background-color: #fff;
  border-color: #dbdbdb;
  color: #363636;
  max-width: 100%;
  width: 100%;
}
</style>