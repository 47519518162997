<template>
  <div id="app">
    <!-- Header -->
    <header>
      <img id="logo" alt="AC Club Logo" src="@/assets/images/acclub-logo.png">
      <div id="nav">
        <div class="nav-item">
          <button @click="$router.push(startingUrl)" class="nav-link" :class="{ active: isLifestylePageActive }">Lifestyle</button>
        </div>
        <div class="nav-item">
          <button @click="$router.push('/air-quality')" :disabled="!hvacBrand" class="nav-link" :class="{ active: isAirQualityPageActive }">Air Quality</button>
        </div>
        <div class="nav-item">
          <button @click="$router.push('/thermostat')" :disabled="!airSanitizer" class="nav-link" :class="{ active: isThermostatPageActive }">Thermostat</button>
        </div>
        <div class="nav-item">
          <button @click="$router.push('/quote')" :disabled="!thermostat" class="nav-link" :class="{ active: isQuotePageActive }">Quote</button>
        </div>
      </div>
    </header>
    <!-- /Header -->
    <!-- Main Content -->
    <main>
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </main>
    <!-- /Main Content -->
    <!-- Footer -->
    <footer>
      <div class="quote-controls" v-show="isQuoteControlsVisible">
        <button @click="$router.push(backButtonUrl)" v-show="isBackButtonVisible" class="btn isOutlined">Back</button>
        <button @click="$router.push(nextButtonUrl)" :disabled="isNextButtonDisabled" class="btn" :class="{isDisabled: isNextButtonDisabled}">Next</button>
      </div>
    </footer>
    <!-- /Footer -->
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  created() {
    // Set Starting URL
    const newUrl = window.location.pathname;
    const {
      performance,
      age,
    } = this.$route.query;
    if (performance === 'broken') {
      localStorage.hvacPerformance = 'Broken & not running';
    } else if (performance === 'struggle') {
      localStorage.hvacPerformance = 'It\'s struggling';
    } else if (performance === 'issue') {
      localStorage.hvacPerformance = 'It\'s running, but has issues';
    } else if (performance === 'good') {
      localStorage.hvacPerformance = 'All good & want to keep it that way';
    } else if (performance === 'business') {
      localStorage.hvacPerformance = 'Good, but I\'m interested in your business model';
    }
    if (age === '3') {
      localStorage.hvacAge = '1 - 6 years old';
    } else if (age === '8') {
      localStorage.hvacAge = '7 - 9 years old';
    } else if (age === '10') {
      localStorage.hvacAge = '10+ years old';
    }
    this.$store.state.startingUrl = newUrl;// Should be => "/lifestyle/:tonnage"
  },
  computed: {
    ...mapState({
      startingUrl: state => state.startingUrl,
      hvacBrand: state => state.hvacBrand,
      airSanitizer: state => state.airSanitizer,
      thermostat: state => state.thermostat,
      isQuoteControlsVisible: state => state.quoteControls.isVisible,
      isBackButtonVisible: state => state.quoteControls.isBackButtonVisible,
      isNextButtonDisabled: state => state.quoteControls.isNextButtonDisabled,
      backButtonUrl: state => state.quoteControls.backButtonUrl,
      nextButtonUrl: state => state.quoteControls.nextButtonUrl,
      isLifestylePageActive: state => state.navigation.isLifestylePageActive,
      isAirQualityPageActive: state => state.navigation.isAirQualityPageActive,
      isThermostatPageActive: state => state.navigation.isThermostatPageActive,
      isQuotePageActive: state => state.navigation.isQuotePageActive,
    }),
  }
}
</script>

<style lang="scss">
// Global Styles
@import "@/assets/scss/normalize.scss";
@import "@/assets/scss/fonts.scss";
@import "@/assets/scss/base.scss";
@import "@/assets/scss/typography";
@import "@/assets/scss/buttons.scss";
@import "@/assets/scss/quote-options.scss";
@import "@/assets/scss/utility.scss";

// Component Styles
.fade-enter-active {
    transition: opacity .25s;
}

.fade-enter, 
.fade-leave-to {
    opacity: 0;
}
</style>
