import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    startingUrl: "",
    tonnage: 0,
    hvacBrand: "",
    airSanitizer: "",
    thermostat: "",
    quoteControls: {
      isVisible: true, //Hide on results/tonnage page
      isBackButtonVisible: false, //Hide on step one/lifestyle
      isNextButtonDisabled: true,
      backButtonUrl: "",
      nextButtonUrl: "",
    },
    navigation: {
      isLifestylePageActive: true,
      isAirQualityPageActive: false,
      isThermostatPageActive: false,
      isQuotePageActive: false,
    },
    hvacPerformance: '',
    hvacAge: '',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
