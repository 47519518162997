import Vue from 'vue'
import VueRouter from 'vue-router'
import HvacOptions from '../views/HvacOptions.vue'
import AirQualityOptions from '../views/AirQualityOptions.vue'
import ThermostatOptions from '../views/ThermostatOptions.vue'
import QuoteResults from '../views/QuoteResults.vue'
import TonnageSelection from '../views/TonnageSelection'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'TonnageSelection',
    component: TonnageSelection
  },
  {
    path: '/lifestyle/:tonnage',
    name: 'HvacOptions',
    component: HvacOptions
  },
  {
    path: '/air-quality',
    name: 'AirQualityOptions',
    component: AirQualityOptions
  },
  {
    path: '/thermostat',
    name: 'ThermostatOptions',
    component: ThermostatOptions
  },
  {
    path: '/quote',
    name: 'QuoteResults',
    component: QuoteResults
  },
  { 
    path: "*", 
    component: TonnageSelection 
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
})

export default router
