const quoteBuilderMixin = {
  filters: {
    pricing(price) {
      if (price === 0) {
        return 'Included';
      }
      return `$${price}/mo`;
    },
  },
};

export default quoteBuilderMixin;
