<template>
  <div class="modal">
      <div class="modal__background"></div>
      <div class="modal__content">
          <h1>What size is your HVAC unit?</h1>
          <button @click="setStartingUrl(2)" class="btn isOutlined">2-tons</button>
          <button @click="setStartingUrl(2.5)" class="btn isOutlined">2.5-tons</button>
          <button @click="setStartingUrl(3)" class="btn isOutlined">3-tons</button>
          <button @click="setStartingUrl(3.5)" class="btn isOutlined">3.5-tons</button>
          <button @click="setStartingUrl(4)" class="btn isOutlined">4-tons</button>
          <button @click="setStartingUrl(5)" class="btn isOutlined">5-tons</button>
          <!-- <button @click="$router.push(startingUrl)" class="nav-link" >5-tons</button> -->
      </div>

  </div>
</template>

<script>
export default {
  name: 'TonnageSelection',
  created() {

    // Hides the bottom quote controls
    this.$store.state.quoteControls.isVisible = false;

  },
  methods: {

    // Set the startingUrl
    // ...then go to the lifestyles step
    setStartingUrl(tons) {

        this.$store.state.tonnage = tons;

        this.$store.state.startingUrl = "/lifestyle/" + tons;

        this.$router.push(this.$store.state.startingUrl);

    }

  }
}
</script>

<style lang="scss">
.modal {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-height: 100vh;
}

.modal__background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.25);
}

.modal__content {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    width: 70%;
    max-width: 300px;
    margin: auto;
    padding: 24px 24px;
    background: white;
    box-shadow: 0 -10px 20px 0 rgba(11, 13, 32, 0.05);
    border-radius: 6px;
    text-align: center;

    & h1 {
        font-size: 18px;
    }

    & .btn {
        margin-top: 8px;
    }
}
</style>