<template>
  <div class="quote-step-container">
    <div class="quote-question">
      <h1>Which thermostat would you like?</h1>
      <p class="subheader">Select an option</p>
    </div>
    <div class="quote-options">
      <div class="option" v-show="isHoneywellOptionVisible" :class="{ active: isHoneywellThermActive }" @click="setThermostat('honeywell')">
        <h2 class="option-title">Honeywell Lyric T6</h2>
        <img class="option-img thermostat" alt="Honeywell Thermostat" src="@/assets/images/honeywell-thermostat.png">
        <div class="option-details">
          <p class="description">Standard thermostat with limited programming abilities.</p>
        </div>
      </div>
      <div class="option" v-show="isNestOptionVisible" :class="{ active: isNestThermActive }" @click="setThermostat('nest')">
        <h2 class="option-title">Nest</h2>
        <img class="option-img thermostat" alt="Nest Thermostat" src="@/assets/images/nest-thermostat.png">
        <div class="option-details">
          <p class="description">Easily program your thermostat to be more efficient.</p>
        </div>
      </div>
      <div class="option" v-show="isWyzeOptionVisible" :class="{ active: isWyzeThermActive }" @click="setThermostat('wyze')">
        <h2 class="option-title">Wyze</h2>
        <img class="option-img thermostat" alt="Wyze Thermostat" src="@/assets/images/wyze-thermostat.png">
        <div class="option-details">
          <p class="description">Easily program your thermostat to be more efficient.</p>
        </div>
      </div>
      <div class="option" v-show="isDaikinOneOptionVisible" :class="{ active: isDaikinThermActive }" @click="setThermostat('daikin')">
        <h2 class="option-title">Daikin One+</h2>
        <img class="option-img thermostat" alt="Daikin Thermostat" src="@/assets/images/daikin-thermostat.png">
        <div class="option-details">
          <p class="description">Communicating Wifi Smart Thermostat</p>
        </div>
      </div>
       <div class="option" v-show="isComfortLinkVisible" :class="{ active: isComfortLinkActive }" @click="setThermostat('trane')">
        <h2 class="option-title">Trane Comfortlink Thermostat</h2>
        <img class="option-img thermostat" alt="Trane Thermostat" src="@/assets/images/trane-thermostat.png">
        <div class="option-details">
          <p class="description">Communicating Wifi Smart Thermostat</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import quoteBuilderMixin from '@/mixins/quoteBuilderMixin';

export default {
  name: 'ThermostatOptions',
  mixins: [quoteBuilderMixin],
  data() {
    return {
      hvacBrand: "",
      honeywellThermostatPrice: 0,
      nestThermostatPrice: 0,
      wyzeThermostatPrice: 0,
      // Card UI State
      isHoneywellOptionVisible: true,
      isNestOptionVisible: true,
      isWyzeOptionVisible: true,
      isDaikinOneOptionVisible: true,
      isComfortLinkVisible: true,
      // Adds/Removes active class on option element
      isHoneywellThermActive: false,
      isNestThermActive: false,
      isDaikinThermActive: false,
      isWyzeThermActive: false,
      isComfortLinkActive: false,
      // Takes off disabled class on next button
      canProceedToNextStep: false 
    }
  },
  created() {

    // Set quote controls visibility and urls
    this.$store.state.quoteControls.isVisible = true;
    this.$store.state.quoteControls.isBackButtonVisible = true;
    this.$store.state.quoteControls.backButtonUrl = "/air-quality";
    this.$store.state.quoteControls.nextButtonUrl = "/quote";
    this.$store.state.quoteControls.isNextButtonDisabled = true;
    const startingUrl = this.$store.state.startingUrl;
    const hvacBrand = this.$store.state.hvacBrand;

    // Set nav link css active class
    this.$store.state.navigation.isLifestylePageActive = false;
    this.$store.state.navigation.isAirQualityPageActive = false;
    this.$store.state.navigation.isThermostatPageActive = true;
    this.$store.state.navigation.isQuotePageActive = false;

    // Page Refresh Route Gaurd - Goes to home
    if (hvacBrand === "") {
      this.$router.push('/');
    }

    // Set option active css state & enable the next button
    const thermostat = this.$store.state.thermostat;
    if (thermostat == "honeywell") {
      this.isHoneywellThermActive = true;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    } 
    else if (thermostat == "nest") {
      this.isNestThermActive = true;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    }
    else if (thermostat == "wyze") {
      this.isWyzeThermActive = true;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    }
    else if (thermostat == "daikin") {
      this.isDaikinThermActive = true;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    }

    // Check to see if the brand is Daikin
    // ...then hide all options except the Daikin one+
    // ...if not, then hide the Daikin one+

    // Set prices and UI options based on the tonnage param
    if (hvacBrand == "daikin") {
      this.isHoneywellOptionVisible = false,
      this.isNestOptionVisible = false,
      this.isWyzeOptionVisible = false,
      this.isDaikinOneOptionVisible = true
    }
    else if(hvacBrand == "trane") {
      this.isHoneywellOptionVisible = false
      this.isNestOptionVisible = false
      this.isWyzeOptionVisible = false;
      this.isDaikinOneOptionVisible = false;


    }
    else {
      this.isHoneywellOptionVisible = true,
      this.isNestOptionVisible = true,
      this.isDaikinOneOptionVisible = false
    }
  
  },
  methods: {

    // Set the thermostat option in the store
    setThermostat(thermostat) {
      
      // Set visual active state on UI
      if (thermostat == "honeywell") {
        this.isHoneywellThermActive = true;
        this.isNestThermActive = false;
        this.isWyzeThermActive = false;
      }
      else if (thermostat == "nest") {
        this.isHoneywellThermActive = false;
        this.isNestThermActive = true;
        this.isWyzeThermActive = false;
      }
      else if (thermostat == "wyze") {
        this.isHoneywellThermActive = false;
        this.isNestThermActive = false;
        this.isWyzeThermActive = true;
      }
      else if (thermostat == "daikin") {
        this.isHoneywellThermActive = false;
        this.isNestThermActive = false;
        this.isWyzeThermActive = false;
        this.isDaikinThermActive = true;
      }
       else if (thermostat == "trane") {
        this.isHoneywellThermActive = false;
        this.isNestThermActive = false;
        this.isWyzeThermActive = false;
        this.isComfortLinkActive = true;
      }

      // Set HVAC Brand state in store
      this.$store.state.thermostat = thermostat;
      this.$store.state.quoteControls.isNextButtonDisabled = false;

    }

  }
}
</script>
