<template>
  <div class="quote-step-container">
    <div class="quote-question">
      <h1>Would you like to add in-system air sanitization?</h1>
      <p class="subheader">Select an option</p>
    </div>
    <div class="quote-options">
      <div class="option" :class="{ active: isPlasmapureActive }" @click="setAirSanitizer('plasmapure')">
        <h2 class="option-title">Air Sanitizer</h2>
        <img class="option-img thermostat" alt="Plasmapure 600" src="@/assets/images/plasmapure-air-sanitizer.png">
        <div class="option-details">
          <div class="price-details">
            <p class="price">+${{plasmapurePrice}}/mo</p>
            <p class="product-full-price">or ${{plasmapureFullPrice}} one-time</p>
          </div>
          <p class="brand">Plasmapure 600</p>
          <p class="description">Efficient Bi-Polar Ionization</p>
        </div>
      </div>
      <div class="option" :class="{ active: isHaloLedActive }" @click="setAirSanitizer('halo-led')">
        <h2 class="option-title">Air Sanitizer w/ UV Light</h2>
        <img class="option-img thermostat" alt="HALO-LED" src="@/assets/images/halo-led-air-sanitizer.png">
        <div class="option-details">
          <div class="price-details">
            <p class="price">+${{haloPrice}}/mo</p>
            <p class="product-full-price">or ${{haloFullPrice}} one-time</p>
          </div>
          <p class="brand">REME HALO-LED</p>
          <p class="description">Bi-Polar Ionization + LED UV Light</p>
        </div>
      </div>
      <div id="no-air-sanitizer" class="option" :class="{ active: isNoAirSanitizerSelected }" @click="setAirSanitizer('none')">
        <h2 class="option-title">No thanks</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AirQualityOptions',
  data() {
    return {
      plasmapurePrice: 7,
      plasmapureFullPrice: 675,
      haloPrice: 10,
      haloFullPrice: 925,
      isNoAirSanitizerSelected: false,
      isPlasmapureActive: false,
      isHaloLedActive: false
    }
  },
  created() {

    // Set quote controls visibility
    this.$store.state.quoteControls.isVisible = true;
    this.$store.state.quoteControls.isBackButtonVisible = true,
    this.$store.state.quoteControls.nextButtonUrl = "/thermostat";
    this.$store.state.quoteControls.isNextButtonDisabled = true;
    const startingUrl = this.$store.state.startingUrl;
    this.$store.state.quoteControls.backButtonUrl = startingUrl;
    const hvacBrand = this.$store.state.hvacBrand;

    // Set nav link css active class
    this.$store.state.navigation.isLifestylePageActive = false;
    this.$store.state.navigation.isAirQualityPageActive = true;
    this.$store.state.navigation.isThermostatPageActive = false;
    this.$store.state.navigation.isQuotePageActive = false;

    // Page Refresh Route Gaurd - Goes to home
    if (hvacBrand === "") {
      this.$router.push('/');
    }

    // Set option active css state & enable the next button
    const airSanitizer = this.$store.state.airSanitizer;
    if (airSanitizer == "plasmapure") {
      this.isPlasmapureActive = true;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    } 
    else if (airSanitizer == "halo-led") {
      this.isHaloLedActive = true;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    }
  
  },
  methods: {

    // Set the air quality option in the store
    setAirSanitizer (sanitizerBrand) {

      // Set visual active state on UI
      if (sanitizerBrand == "none") {
        this.isNoAirSanitizerSelected = true;
        this.isPlasmapureActive = false;
        this.isHaloLedActive = false;
      }
      else if (sanitizerBrand == "plasmapure") {
        this.isNoAirSanitizerSelected = false;
        this.isPlasmapureActive = true;
        this.isHaloLedActive = false;
      }
      else if (sanitizerBrand == "halo-led") {
        this.isNoAirSanitizerSelected = false;
        this.isPlasmapureActive = false;
        this.isHaloLedActive = true;
      }
      // Set Air Sanitizer Brand state in store
      this.$store.state.airSanitizer = sanitizerBrand;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    }


  }
}
</script>
