<template>
  <div class="quote-step-container">
    <div class="quote-question">
      <h1>Which HVAC system do you prefer?</h1>
      <p class="subheader">Select an option</p>
    </div>
    <div class="quote-options">
      <div class="option" :class="{ active: isStandardActive }" @click="setHvacBrand('goodman')">
        <h2 class="option-title">Dependable and Efficient</h2>
        <img class="option-img" alt="Goodman Unit" src="@/assets/images/goodman-unit.png">
        <div class="option-details">
          <div class="price-details">
            <p class="price">${{goodmanPrice}}/mo</p>
          </div>
          <p class="brand">Goodman</p>
          <p class="description">{{tonnage}}-ton | 16 SEER Single Stage</p>
          <p class="description">25% Energy Savings vs 10 SEER</p>
          <p class="description">Energy Efficient Compressor</p>
        </div>
      </div>
      <div class="option" v-show="isDaikinOptionActive" :class="{ active: isEnergySaverActive }" @click="setHvacBrand('daikin')">
        <h2 class="option-title">Lower Humidity and More Energy Savings</h2>
        <img class="option-img" alt="Goodman Unit" src="@/assets/images/daikin-unit.png">
        <div class="option-details">
          <div class="price-details">
            <p class="price">${{daikinPrice}}/mo</p>
          </div>
          <p class="brand">Daikin</p>
          <p class="description">{{tonnage}}-ton | 18 SEER Inverter Technology</p>
          <p class="description">47% Energy Savings vs 10 SEER</p>
          <p class="description">Consistently Maintains Temperature</p>
          <p class="description">Communicating Thermostat</p>
        </div>
      </div>
      <div class="option" v-show="isTraneOptionActive" :class="{ active: isHighPerformanceActive }" @click="setHvacBrand('trane')">
        <h2 class="option-title">Highest Efficiency and Energy Savings</h2>
        <img class="option-img" alt="Goodman Unit" src="@/assets/images/trane-unit.png">
        <div class="option-details">
          <div class="price-details">
            <p class="price">${{tranePrice}}/mo</p>
          </div>
          <p class="brand">Trane XV20i</p>
          <p class="description">{{tonnage}}-ton | 22 SEER Variable Speed</p>
          <p class="description">64% Energy Savings vs 10 SEER</p>
          <p class="description">750 Stages for Max Efficiency</p>
          <p class="description">Communicating Thermostat</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HvacOptions',
  data() {
    return {
      tonnage: 0,
      goodmanPrice: 0,
      daikinPrice: 0,
      tranePrice: 0,
      // Some units are not shown with certain tonnages
      isGoodmanOptionActive: true,
      isDaikinOptionActive: true,
      isTraneOptionActive: true,//<-Currently backordered but will be available later
      // Adds/Removes active class on option element
      isStandardActive: false,
      isEnergySaverActive: false,
      isHighPerformanceActive: false,
      // Takes off disabled class on next button
      canProceedToNextStep: false 
    }
  },
  created() {

    // Set quote controls visibility
    this.$store.state.quoteControls.isVisible = true;
    this.$store.state.quoteControls.isBackButtonVisible = false,
    this.$store.state.quoteControls.nextButtonUrl = "/air-quality";

    // Set nav link css active class
    this.$store.state.navigation.isLifestylePageActive = true;
    this.$store.state.navigation.isAirQualityPageActive = false;
    this.$store.state.navigation.isThermostatPageActive = false;
    this.$store.state.navigation.isQuotePageActive = false;

    // Grab URL Parameter to load the right hvac unit selections
    const tonnage = this.$route.params.tonnage;
    this.$store.state.tonnage = tonnage;
    this.tonnage = tonnage;

    // Shows the bottom quote controls
    this.$store.state.quoteControls.isVisible = true;

    // Set option active css state & enable the next button
    const hvacBrand = this.$store.state.hvacBrand;
    if (hvacBrand == "goodman") {
      this.isStandardActive = true;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    } 
    else if (hvacBrand == "daikin") {
      this.isEnergySaverActive = true;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    }
    else if (hvacBrand == "trane") {
      this.isHighPerformanceActive = true;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    }

    // Set prices and UI options based on the tonnage param
    if (tonnage == 2) {
      this.goodmanPrice = 78;
      this.daikinPrice = 104;
      this.tranePrice = 120;
    };

    if (tonnage == 2.5) {
      this.goodmanPrice = 78;
      this.isStandardActive = true,
      this.$store.state.hvacBrand = "goodman";
      this.isDaikinOptionActive = false; // Hide Daikin unit
      this.isTraneOptionActive = false;  // Hide Trane unit
    };

    if (tonnage == 3) {
      this.goodmanPrice = 79;
      this.daikinPrice = 109;
      this.tranePrice = 125;
    };

    if (tonnage == 3.5) {
      this.goodmanPrice = 85;
      this.isStandardActive = true,
      this.$store.state.hvacBrand = "goodman";
      this.isDaikinOptionActive = false; // Hide Daikin unit
      this.isTraneOptionActive = false;  // Hide Trane unit
    };

    if (tonnage == 4) {
      this.goodmanPrice = 89;
      this.daikinPrice = 119;
      this.tranePrice = 131;
    };

    if (tonnage == 5) {
      this.goodmanPrice = 99;
      this.daikinPrice = 125;
      this.tranePrice = 140;
    };
  
  },
  mounted() {
    const {
      tonnage,
    } = this.$route.params;
    const selectedTonnage = Number(tonnage);
    if (selectedTonnage !== 2 &&
      selectedTonnage !== 2.5 &&
      selectedTonnage !== 3 &&
      selectedTonnage !== 3.5 &&
      selectedTonnage !== 4 &&
      selectedTonnage !== 5 ) {
      this.$router.replace('/');
    }
  },
  methods: {

    setHvacBrand(brand) {

      // Set visual active state on UI
      if (brand == "goodman") {
        this.isStandardActive = true;
        this.isEnergySaverActive = false;
        this.isHighPerformanceActive = false;
        this.$store.state.thermostat = "";
      }
      else if (brand == "daikin") {
        this.isStandardActive = false,
        this.isEnergySaverActive = true,
        this.isHighPerformanceActive = false
        this.$store.state.thermostat = "";
      }
      else if (brand == "trane") {
        this.isStandardActive = false,
        this.isEnergySaverActive = false,
        this.isHighPerformanceActive = true
        this.$store.state.thermostat = "";
      }

      // Set HVAC Brand state in store
      this.$store.state.hvacBrand = brand;
      this.$store.state.quoteControls.isNextButtonDisabled = false;
    },

  }
}
</script>